/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div class=\"container segment login-form\" ng-controller=\"PasswordResetRequestController as prr_controller\" ng-show=\"!processing\">\n<div ng-show=\"prr_controller.reset_password_sent\">\n<div class=\"alert alert-success\">\nPlease check your email for a link to reset your password.\n</div>\n</div>\n<form name=\"login_form\" ng-submit=\"prr_controller.request_password_reset()\" ng-show=\"!prr_controller.reset_password_sent\">\n<p class=\"instructions\">\nForgot your ClinicAid password? Enter your email address and we will send you a link to reset your password.\n</p>\n<div class=\"alert alert-danger\" ng-show=\"prr_controller.error_message\">\n{{prr_controller.error_message}}\n</div>\n<div class=\"form-group\">\n<label>Email Address</label>\n<div class=\"input-group\">\n<span class=\"input-group-addon\">\n<i class=\"fa fa-envelope\"></i>\n</span>\n<input id=\"input-email\" type=\"email\" class=\"form-control\" name=\"email\" placeholder=\"Email\" ng-model=\"prr_controller.email\" required autofocus autocomplete=\"off\"/>\n</div>\n</div>\n<span class=\"buttons\">\n<button type=\"submit\" class=\"button blue\">Request Password Reset<span class=\"bar\">&nbsp;</span></button>\n</span>\n</form>\n</div>\n";
var id1="code/password/password-reset-request.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
